// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
// $darkGrey: #3c4044;
// $lightGrey: #939597;
// $darkBlue: #014c75;
// $seaGreen: #0b3f33;

$theme-colors: (
  // Black theme
  'blackish-primary': #3c4044,
  'blackish-secondary': #2a2d30,
  'blackish-light': rgba(170, 170, 170, 0.5),
  'blackish-extra-light': rgba(170, 170, 170, 0.15),

  // Blue Theme
  'blue-primary': #2196f3,
  'blue-secondary': #0c83e2,
  'blue-light': rgba(33, 150, 243, 0.5), 
  'blue-extra-light': rgba(33, 150, 243, 0.15),

  // Purple theme
  'purple-primary': #ba68c8,
  'purple-secondary': #883997,
  'purple-light': rgba(186, 104, 200, 0.5),
  'purple-extra-light': rgba(186, 104, 200, 0.15),

  // Pink theme
  'pink-primary': #f06292,
  'pink-secondary': #ba2d65,
  'pink-light': rgba(240, 98, 145, 0.5),
  'pink-extra-light': rgba(240, 98, 145, 0.15),


  // Red theme
  'red-primary': #ff4c52,
  'red-secondary': #e42d3d,
  'red-light': rgba(255, 76, 82, 0.5),
  'red-extra-light': rgba(255, 76, 82, 0.15),

  // Teal theme
  'teal-primary': #009688,
  'teal-secondary': #007065,
  'teal-light': rgba(0, 150, 135, 0.5),
  'teal-extra-light': rgba(0, 150, 135, 0.15),

  // Green theme
  'green-primary': #4caf50,
  'green-secondary': #409444,
  'green-light': rgba(76, 175, 79, 0.5),
  'green-extra-light': rgba(76, 175, 79, 0.15),

  // Sea Green theme
  'sea-green-primary': #0b3f33,
  'sea-green-secondary': #0a352b,
  'sea-green-light': rgba(11, 63, 51, 0.493),
  'sea-green-extra-light': rgba(11, 63, 51, 0.15),

  // Orange theme
  'orange-primary': #ff9800,
  'orange-secondary': #f57c00,
  'orange-light': rgba(255, 153, 0, 0.5),
  'orange-extra-light': rgba(255, 153, 0, 0.15),


  // Turquoise theme
  'turquoise-primary': #009184,
  'turquoise-secondary': #00D0BD,
  'turquoise-light': rgba(0, 208, 191, 0.5),
  'turquoise-extra-light': rgba(0, 208, 191, 0.15),

  

  'blue': #2196f3,
  'indigo': #536dfe,
  'purple': #9c27b0,
  'pink': #ff4081,
  'red': #f44336,
  'orange': #ff9800,
  'yellow': #ffeb3b,
  'green': #4caf50,
  'teal': #009688,
  'tealPrimary': #009688,
  'tealSecondary': #007065,
  'cyan': #00bcd4,
  'white': #fff,
  'darkGrey': #3c4044,
  'lightGrey': #939597,
  'darkBlue': #014c75,
  'seaGreen': #0b3f33,
  "primary": #6a82fb,
  "secondary": #fc5c7d,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  "active": #5fdba7, 
  'archived': #808080,
  "trashed": #ff3739,
  'turquoisePrimary': #00D0BD, 
  'turquoiseSecondary': #009184, 
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 170px;
