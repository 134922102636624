@import "variables";

// libraries
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-infinite-calendar/styles";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";

// components
@import "components/header";
@import "components/sidebar";
@import "components/content";
@import "components/page";
@import "components/widget";
@import "components/search-input";
@import "components/payslip";

// themes
@import "themes/colors";

// demos (only for demo pages)
@import "demos/demos";

.cr-app {
  background-color: #f9f9f9 !important;
}
// Custom CSS
body {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden;
  font-family: "Poppins", "Roboto", "Helvetica Neue", Arial, sans-serif;
}
a:not([href]):not([tabindex]) {
  cursor: pointer;
}
::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  border-radius: 5px;
  cursor: pointer;
}

.DashboardPage {
  overflow: overlay;
  height: calc(100vh - 65px) !important;
  min-height: auto;
}
.Cal__MonthList__root {
  height: calc(100vh - 320px) !important;
}
.wd-10 {
  width: 10%;
}
.wd-15 {
  width: 15%;
}
.wd-20 {
  width: 20%;
}
.wd-25 {
  width: 25%;
}
.wd-30 {
  width: 30%;
}
.wd-35 {
  width: 35%;
}
.wd-40 {
  width: 40%;
}
.wd-45 {
  width: 45%;
}
.wd-50 {
  width: 50%;
}
.wd-60 {
  width: 60%;
}
.wd-65 {
  width: 65%;
}
.wd-70 {
  width: 70%;
}
.wd-80 {
  width: 80%;
}
.wd-85 {
  width: 85%;
}
.wd-90 {
  width: 90%;
}
.max-wd-30vw {
  max-width: 30vw !important;
}
.max-wd-50vw {
  max-width: 50vw !important;
}
.max-wd-60vw {
  max-width: 60vw !important;
}
.max-wd-80vw {
  max-width: 80vw !important;
}
.min-w-90px {
  min-width: 90px;
}
.min-w-100px {
  min-width: 100px;
}
.min-w-140px {
  min-width: 140px;
}
.wd-220px {
  width: 220px !important;
}
.h-250 {
  height: 250px;
}
.lh-14 {
  line-height: 14px;
}
.pr10 {
  padding-right: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pr5 {
  padding-right: 5px;
}
.pl5 {
  padding-left: 5px;
}
.pl15 {
  padding-left: 15px;
}
.pr15 {
  padding-right: 15px;
}
.pt15 {
  padding-top: 15px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.m15 {
  margin: 15px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mr15 {
  margin-right: 15px;
}
.br-0 {
  border-radius: 0px !important;
}
.btn-size {
  min-width: 100px;
  height: 32px;
}
.h-32 {
  height: 32px;
}
.h-38 {
  height: 38px;
}
.h-100px {
  height: 100px;
}
.h-120px {
  height: 120px;
}
.h-130px {
  height: 130px;
}
.h-150px {
  height: 150px;
}
.min-h-150 {
  min-height: 150px;
}
.noClick {
  pointer-events: none;
}
.min-h-32 {
  min-height: 32px !important;
}
.filterBtn {
  .btn {
    padding: 4px 8px;
    font-size: 12px;
  }
}
.filterDropdown {
  .btn {
    border-radius: 0.25rem !important;
  }
}
.font14 {
  font-size: 14px !important;
}
.cr-page .row .col,
.cr-page .row > [class^="col-"] {
  margin-bottom: 0;
}
.actionButtonWrapper a:hover {
  text-decoration: none;
}
// General CSS

.scroll {
  // overflow: overlay;
  overflow: auto;
}
.reportsScroll {
  height: calc(100vh - 203px);
}
.reportsScroll canvas.chartjs-render-monitor {
  height: 100% !important;
}
.colListscroll {
  height: calc(100vh - 190px);
}
.employeeViewScroll {
  height: calc(100vh - 145px);
}
.addEmployeeScroll {
  height: calc(100vh - 242px);
}
.salaryDetailScroll {
  height: calc(100vh - 267px);
}
.paySlipScroll {
  height: calc(100vh - 150px);
}
.viewEmployeeScroll {
  height: calc(100vh - 337px);
}
.sp-col-scroll {
  height: calc(100vh - 230px) !important;
}
.cardBodyHeightWithFooter {
  height: calc(100vh - 261px);
  overflow: hidden;
}
.cardBodyHeightWithoutFooter {
  height: calc(100vh - 184px);
  overflow: hidden;
  position: relative;
}
.cardBodyHeightWithoutHeaderFooter {
  height: calc(100vh - 108px);
  overflow: hidden;
  position: relative;
}
.themeColorCard {
  height: calc(100vh - 164px);
  overflow: hidden;
  position: relative;
}
.myTicketsTable .table-wrapper-scroll-y{
  height: calc(100vh - 424px);
  overflow: overlay;
}
.permissionTable .table-wrapper-scroll-y,
.rolesTable .table-wrapper-scroll-y,
.partnersTable .table-wrapper-scroll-y,
.recruiterTable .table-wrapper-scroll-y,
.usersTable .table-wrapper-scroll-y,
.projectsTable .table-wrapper-scroll-y {
  height: calc(100vh - 324px);
}
.recTechTable .table-wrapper-scroll-y,
.sitesTable .table-wrapper-scroll-y {
  height: calc(100vh - 445px);
}
.countriesTable .table-wrapper-scroll-y,
.citiesTable .table-wrapper-scroll-y,
.generalSettingsTable .table-wrapper-scroll-y {
  height: calc(100vh - 367px);
}
.techTable .table-wrapper-scroll-y,
.usersDetailLoginHistoryTable .table-wrapper-scroll-y {
  height: calc(100vh - 490px);
}
.loginApprovalsTable .table-wrapper-scroll-y {
  height: calc(100vh - 247px);
}

.gridView,
.listView {
  height: calc(100% - 92px);
}
h1,
h2,
h3,
h4,
p {
  margin-bottom: 0;
}
ul,
li {
  list-style: none;
  margin-bottom: 0;
}
.btn {
  font-size: 13px;
  box-shadow: none !important;
  outline: none !important;
}
.btn-secondary {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.btn-outline-secondary {
  &:hover {
    color: #fff;
  }
}
.cr-search-form__input {
  outline: none !important;
  box-shadow: none !important;
}

sup {
  color: #e22626;
  font-size: 100%;
  top: -1px;
}
textarea.form-control {
  height: 100px;
  resize: none;
}
.form-control {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  box-shadow: none !important;
  outline: none !important;
  min-height: 38px;
}

input[type="number"] {
  text-align: right;
}

[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.intl-tel-input {
  display: block !important;
}
.css-26l3qy-menu {
  z-index: 99 !important;
}
.css-1rhbuit-multiValue,
.css-12jo7m5,
.css-xb97g8:hover {
  color: #fff !important;
}
.css-yk16xz-control {
  color: rgba(0, 0, 0, 0.87);
  border-color: #ced4da !important;
  box-shadow: none !important;
  outline: none !important;
}
.css-1pahdxg-control {
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.css-yt9ioa-option:active,
.css-9gakcf-option {
  color: #fff;
}
.css-1uccc91-singleValue {
  color: rgba(0, 0, 0, 0.87) !important;
}
.css-tlfecz-indicatorContainer,
.css-g1d714-ValueContainer,
.css-1gtu0rj-indicatorContainer,
.css-9gakcf-option,
.css-yt9ioa-option,
.css-1n7v3ny-option {
  cursor: pointer !important;
}
.css-4ljt47-MenuList {
  max-height: 150px !important;
}
.filterBox .css-1gtu0rj-indicatorContainer,
.filterBox .css-tlfecz-indicatorContainer {
  padding: 5px !important;
}
.filterBox .css-1pahdxg-control,
.filterBox .css-yk16xz-control {
  min-height: 32px !important;
  border-radius: 5px;
}
.filterBox .css-1hwfws3,
.filterBox .css-g1d714-ValueContainer {
  padding: 1px 8px !important;
}
.filterBox .css-1wa3eu0-placeholder {
  font-size: 12px;
}
.filterBox .react-datepicker-wrapper input {
  min-height: 32px;
  height: 32px;
}
.filterBox .react-datepicker__input-container:after {
  line-height: 15px;
}
.css-tj5bde-Svg {
  width: 16px;
  height: 16px;
}
.form-control:focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  border-color: #ced4da;
}
.card-header {
  text-transform: inherit;
}
label {
  margin-bottom: 2px;
  font-size: 12px;
}
.modal-footer,
.modal-header {
  border: none;
  justify-content: center;
}
.modal-title {
  font-weight: 500;
}
.card-header,
.card-body,
.card-footer {
  padding: 10px 15px;
}
.list-group-flush .list-group-item {
  font-size: 13px;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.87);
}
// Pages Css
.table {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
}
.empDetailTable th,
.empDetailTable td {
  border: none !important;
}
.dataTables_scroll {
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
}

.dataTables_wrapper .row {
  align-items: center;
}
.dataTables_wrapper > .row:first-child > [class^="col-"] {
  margin-bottom: 10px;
}
.dataTables_scrollHeadInner {
  padding-right: 0 !important;
}
.table-wrapper-scroll-y {
  margin: 10px 0px;
}
.dataTables_wrapper .dataTables_length label,
.dataTables_wrapper .dataTables_filter label,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  margin-bottom: 0;
  font-weight: 400;
}

.dataTables_length select {
  width: auto;
  min-height: 32px;
}
.mdb-datatable-filter {
  width: 50%;
}
.mdb-datatable-filter .form-control {
  margin: 0 !important;
  min-height: 32px;
}
.table thead th {
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  font-weight: 500;
  font-size: 13px;
  border-bottom-width: 1px;
  color: #b5b5c3;
  padding: 10px;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.5em;
  display: block;
  opacity: 0.3;
  cursor: pointer;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\F0DE";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\F0DD";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
.empDetailTable th {
  font-weight: 500;
}
.table td {
  font-size: 13px;
  font-weight: 300;
  vertical-align: middle;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  border-top: none;
  border-bottom: 1px solid #dee2e6;
}
.table tbody tr:last-child td {
  border-bottom: none;
}
.table td span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
}
.table tbody tr:hover {
  background-color: #f9f9f9;
}
.dataTables_paginate .pagination {
  justify-content: flex-end;
  margin-bottom: 0;
}
.tableActions {
  text-align: center;
}
.tableActions .btn {
  padding: 5px;
  line-height: 5px;
  border-radius: 5px;
  font-size: 10px;
}

.tableWrapper .table thead th {
  border-top: none;
  padding: 5px 10px;
}
.tableSelect .css-tlfecz-indicatorContainer,
.tableSelect .css-1gtu0rj-indicatorContainer {
  padding: 6px !important;
}
.tableInput .css-yk16xz-control,
.tableSelect .css-1pahdxg-control .tableSelect .css-yk16xz-control {
  min-height: 30px !important;
}
.tableSelect .css-g1d714-ValueContainer {
  padding: 0px 8px !important;
}
.tableInput .form-control {
  min-height: 32px;
  height: calc(1.5em + 0.75rem - 1px) !important;
}
.toggleInput .form-control {
  min-height: 38px;
  height: calc(1.5em + 0.75rem - 1px) !important;
}
.tableCheck .checkmark {
  top: 3px;
  left: 0;
}
.tableCheck .checkBox {
  padding-left: 20px !important;
}
.tableBtn.btn-outline-secondary {
  border-color: #fff;
  color: #fff;
  padding: 0px 5px !important;
  height: 25px;
  min-width: 110px !important;
  font-size: 12px;
}

.tableBtn.btn-outline-secondary:hover {
  background-color: #fff;
}
.tableInput {
  .input-group {
    .btn-outline-secondary {
      padding: 0px;
      border-color: #ced4da;
      width: 35px;
    }
  }
}
.currencyField {
  .css-1pahdxg-control,
  .css-yk16xz-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none;
  }
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.toggleInput {
  .input-group {
    .btn-outline-secondary {
      padding: 0px;
      height: 38px;
      width: 50%;
    }
    .form-control,
    .css-1pahdxg-control,
    .css-yk16xz-control {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: 0 !important;
    }
    .input-group-prepend,
    .css-2b097c-container,
    .form-control {
      flex: 1;
    }
  }
}
.select-currency {
  width: 100%;
  border-right: 1px solid #ced4da;
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 45px;
    width: 100%;
    border: 1px solid #ced4da;
    background-color: #fff;
    font-size: 13px;
    border-radius: 5px;
    max-height: 13em;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0 1px 10px -1px rgba(0, 0, 0, 0.2);
  }
  input.react-autosuggest__input {
    padding: 0.375rem 0.75rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    box-shadow: none !important;
    outline: none !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: 400;
    line-height: 1.5;
    min-height: 38px;
    height: calc(1.5em + 0.75rem - 1px) !important;
    border-right: 1px solid #ced4da;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }
}

.statusBadge {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 22px;
  padding: 5px;
  color: #fff;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.statusBadgePayroll {
  width: 100px;
  font-size: 100%;
  padding: 8px;
}
.page-item .page-link {
  border: 0;
  outline: 0;
  padding: 0.3rem 0.75rem;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.2s linear;
}
.page-item.active .page-link {
  color: #fff !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
}

.cr-page__header {
  display: none;
}

// Custom CSS
.filterBox label {
  margin-bottom: 0;
  text-transform: capitalize;
  margin-right: 3%;
}
.filterBox .css-2b097c-container {
  width: 100%;
}

.colList ul {
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
}

.colList > ul {
  padding-right: 15px;
}

.colList > ul > li:first-child {
  margin-top: 0;
}
.colList ul li {
  margin: 10px 0px;
  border-radius: 5px;
  position: relative;
}
.colList ul li:last-child {
  margin-bottom: 0;
}
.colList.detailViewList li span {
  display: block;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  text-decoration: none;
  transition: 0.3s ease-in;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  z-index: 2;
  width: 100%;
}
.colList ul li .form-check-label,
.colList ul li a {
  display: block;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  text-decoration: none;
  transition: 0.3s ease-in;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
}
.colList ul li .form-check-label:hover,
.colList ul li .form-check-label.active,
.colList ul li a.active,
.colList ul li a:hover {
  transition: 0.3s ease-out;
  background-color: #f9f9f9;
}
.colList .form-check-input {
  margin: auto;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
}
.colList h2 {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 15px;
}
.tabInnerContent {
  .colList {
    li {
      margin: 0px 1% 1% 0px;
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 49%;
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
      .form-check-label {
        width: 100%;
      }
    }
  }
}

.listActions {
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translateY(-50%);
  transition: 0.5s all;
}

.listActions .btn {
  padding: 0px 10px;
  border-radius: 0;
  line-height: 39px;
  outline: none;
  box-shadow: none !important;
  border: none !important;
  font-size: 13px;
  position: relative;
}

.colList ul li a:hover .listActions {
  right: 0px;
  transition: 0.5s all;
}
.checkBox {
  padding-left: 40px !important;
  position: relative;
  cursor: pointer;
}
.checkBox2 {
  padding-left: 20px !important;
}
.checkBox2 .checkmark {
  top: 3px;
  left: 0px;
}

.checkBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 10px;
  left: 16px;
  height: 14px;
  width: 14px;
  border-radius: 3px;
  border: 1px solid;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkBox input:checked ~ .checkmark:after {
  display: block;
}
.checkBox .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.treeList ul {
  padding-left: 15px;
}

.treeList li ul {
  padding-left: 30px;
}

.treeList li:before,
.treeList li:after {
  content: "";
  position: absolute;
  background-color: #dfdfdf;
}
.treeList li:before {
  width: 15px;
  left: -15px;
  height: 1px;
  top: 20px;
}
.treeList li:after {
  left: -15px;
  width: 1px;
}

.treeList li li:after {
  top: -10px;
  height: 100%;
}

.treeList li:after {
  top: 20px;
  height: calc(100% + 11px);
}

.treeList li:last-child:after {
  display: none;
}

.treeList li li:last-child:after {
  display: block;
}
.treeList li li:first-child:after {
  top: -18px;
}
.treeList li li + li:after {
  top: -30px;
  height: calc(100% + 11px);
}
.listActions .btn:first-child {
  border-right: 1px solid #fff !important;
}
.tab-content {
  padding-top: 15px;
}
.tab-pane .tabInnerContent {
  padding-right: 15px;
}
.nav.nav-tabs {
  border-bottom-width: 3px;
  margin: 0 auto;
  border-bottom: none;
}

.nav-tabs {
  .nav-item {
    margin-bottom: 0;
    width: 12.5%;
  }
}
.tabSet-7 {
  .nav-item {
    width: 14.28%;
  }
}
.tabSet-6 {
  .nav-item {
    width: 16.66%;
  }
}
.nav-tabs .nav-item .nav-link {
  text-align: center;
  justify-content: center;
  border: none;
  border-bottom: 4px solid;
  font-weight: 500;
  padding: 10px;
  background-color: #fff !important;
}

._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
  padding: 20px 0px 0px;
}

._2oSbenaQCQZPXOD75Sj6nE {
  width: 158px !important;
  height: 158px !important;
}

canvas._1DghVSjROos1S_s0jPt9TQ {
  width: 100%;
  height: 100%;
}

p.fe6xZVPr1kb0CJksW39zV {
  font-size: 100%;
  margin-bottom: 0;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
}

button.eXEoGG26iwSi6UiZD8FI6 {
  outline: none !important;
}

.YpD9o6H9LydyYmaPWOwt:hover {
  background-color: #fff !important;
}

.popover,
.notifications-tr {
  z-index: 9999 !important;
}
.react-datepicker {
  font-family: "Poppins" !important;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid #ced4da !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  .react-datepicker__triangle {
    display: none;
  }
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: rgba(0, 0, 0, 0.87);
}
.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker__navigation--next {
  border-left-color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker-wrapper input {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  box-shadow: none !important;
  outline: none !important;
  text-transform: capitalize;
  min-height: 38px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker__input-container:after {
  content: "\f073";
  position: absolute;
  top: 0px;
  right: 1em;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  color: #ced4da;
  line-height: 21px;
  border-left: 1px solid #ccc;
  padding-left: 10px;
  margin: 8px auto;
}
.infoWrapperTop {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #ced4da;
    box-shadow: 1px 3px 6px #ced4da;
  }
  .salarySummaryBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;
    border-radius: 5px;
    width: 190px;
    label {
      &:last-child {
        font-weight: 700;
        padding-left: 5px;
      }
    }
  }
  label {
    margin-bottom: 0;
  }
}
.salarySummaryBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
  width: 190px;
  label {
    margin-bottom: 0;
    &:last-child {
      font-weight: 700;
      padding-left: 5px;
    }
  }
}
.infoWrapperBottom {
  position: relative;
  margin-top: 16px;
  padding-top: 10px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    border-top: 1px solid #ced4da;
    box-shadow: -1px -3px 6px #ced4da;
  }
  .salarySummaryBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    label:last-child {
      font-weight: 700;
    }
  }
  label {
    margin-bottom: 0;
  }
}
.cardBox {
  border-radius: 6px;
  .prePaidHeader.card-header {
    padding: 14.5px 15px;
  }
  .card-header {
    color: #fff;
    h2 {
      font-size: 14px;
      margin-bottom: 0;
      span {
        font-weight: 700;
      }
    }
    .accArrowIcon {
      padding: 0;
      background-color: transparent !important;
      color: #fff !important;
      margin-right: 5px;
      border: none !important;
    }
  }
  .card-sub-header {
    padding: 8px 12px;
    margin-bottom: 10px;
    border-radius: 5px;
    h3 {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  .additionalFields {
    h4 {
      font-size: 13px;
      font-weight: 500;
      padding: 10px;
      display: block;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  }
}
.userImage {
  width: 156px;
  height: 156px;
  overflow: hidden;
  border-color: rgba(170, 170, 170, 0.5);
  background: rgba(170, 170, 170, 0.15);
  border-radius: 5px;
}
.pocImage {
  background-color: #f7f7f7 !important;
  border: 2px solid #cbcbcb !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
}
.loginWrapper {
  height: 100vh;
  .loginLeftWrapper {
    font-weight: 200;
    color: #fff;
    background-image: url("../assets/img/sidebar/sidebar-1.jpg");
    background-position: right center;
    background-size: cover;
    transition: left 0.1s ease-in;
    position: relative;
    &::after,
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
    }

    &::before {
      background: #000;
      opacity: 0.33;
    }
    &::after {
      z-index: 3;
      opacity: 0.77;
    }
    .loginContent {
      z-index: 99;
      width: 80%;
      margin: 0 auto;
    }
  }
  .loginRightWrapper {
    .loginFormContent {
      z-index: 99;
      width: 80%;
      margin: 0 auto;
    }
  }
  .alreadyTextWrapper a,
  .forgetPassword a {
    color: rgba(0, 0, 0, 0.87);
  }
  .rememberMe {
    .checkBox {
      padding-left: 20px !important;
    }
    .checkmark {
      top: 2px;
      left: 0;
    }
  }
  .titleWrapper {
    h3 {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 4px solid;
      display: inline-block;
    }
  }
}

.filterActions h2 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
.slipEmpDetail {
  border-top: 1px solid #dee2e6;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  th,
  td {
    border: none !important;
    padding: 0.5rem 5px;
  }
  th {
    width: 15%;
    font-weight: 600;
  }
  td {
    width: 30%;
  }
}
.slipCompanyDetail {
  th,
  td {
    border: none !important;
    padding: 5px;
  }
  th {
    font-size: 18px;
  }
}
.leftTable {
  height: 100%;
}
.rightTable {
  height: 100%;
}
.slipSalaryDetail {
  tr:hover {
    background-color: #fff !important;
  }
  thead {
    th {
      color: rgba(0, 0, 0, 0.87);
      padding: 0.5rem 0.75rem;
      font-weight: 600;
    }
    th:nth-child(2n + 2) {
      text-align: right;
      width: 35%;
    }
  }
  tbody {
    th {
      font-weight: 500;
    }
    th,
    td {
      border: 1px solid #dee2e6 !important;
      height: 36px;
      border-top: none;
      padding: 0.5rem 0.75rem;
      vertical-align: middle;
    }
    td:nth-child(2n + 2) {
      text-align: right;
    }
    td > tr th,
    td > tr td {
      padding: 0;
      border-bottom: none !important;
    }
    td > tr td {
      padding: 0px 0.5rem;
    }
    .trTotal th,
    .trTotal td {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
.slipSalarTotal tbody td {
  border-left: none !important;
}
.slipSalarTotal tbody th {
  border-right: none !important;
}
.slipSalarTotal .trTotal th,
.slipSalarTotal .trTotal td {
  border-top: none !important;
}
.leftTable .slipSalarTotal tbody td {
  border-right: none !important;
}

.leftTable .slipSalaryDetail thead th:first-child {
  border-left: 1px solid #dee2e6;
}
.rightTable .slipSalaryDetail thead th:last-child {
  border-right: 1px solid #dee2e6;
}

.incomTaxDetail {
  th:nth-child(2n + 1) {
    width: 20%;
  }
}

// Updated CSS for Field Technician

.popover {
  border: none;
  .popover-body {
    .card {
      border: none;
      border-radius: 5px !important;
      background-color: #fff;
      transition: all 0.4s ease-in-out 0s;
      box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
        0 2px 5px 1px rgba(69, 65, 78, 0.04);
      .card-subtitle {
        margin-top: 0 !important;
      }
      .filterWrapper {
        padding: 15px;
        background-color: #fff;
      }
    }
  }
}

.card.custCard {
  background-color: transparent;
  display: block;
  border: none;
  .card-header,
  .card-body,
  .card-footer {
    padding: 15px;
    border: none;
    border-radius: 5px !important;
    background-color: #fff;
    transition: all 0.4s ease-in-out 0s;
    // box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
      0 2px 5px 1px rgba(69, 65, 78, 0.04);
  }
  .card-header {
    margin-bottom: 15px;
  }
  .card-footer {
    margin-top: 15px;
  }
  .card-body-top {
    padding-bottom: 15px;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 15px;
  }
  .tab-content {
    padding-top: 0px;
  }
}
.filterLinks {
  .nav-tabs {
    .nav-item {
      width: 12.2%;
    }
  }
  a {
    padding: 0px 0px 5px 0px !important;
    border-bottom: 2px solid !important;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      transition: all 0.3s ease-in-out 0s;
    }
  }
}
.hideListView {
  .listViewWrapper {
    left: -60%;
    transition: left 0.1s ease-in;
  }
  .mapViewWrapper {
    margin: 0;
    transition: margin 0.1s ease-in;
  }
}
.listViewWrapper {
  width: 60%;
  position: absolute;
  left: 0;
  transition: left 0.2s ease-in;
  .searchWrapper {
    .form-control {
      min-height: 28px !important;
      height: 28px;
    }
  }
}
.card-header {
  .searchWrapper {
    .form-control {
      min-height: 32px !important;
      height: 32px;
    }
  }
}
.mapViewWrapper {
  position: relative;
  background-color: #f9f9f9;
  overflow: hidden;
  width: 100%;
  margin: 0% 0% 0% 60%;
  transition: margin 0.2s ease-in;
  .filterBtn {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    .btn {
      svg {
        transition: 0.3s ease-in;
      }
    }
  }
}
.hideListView .mapViewWrapper .filterBtn .btn svg {
  transition: 0.3s ease-in;
  transform: rotate(180deg);
}
.listView {
  .userListing {
    margin: 0px 0% 2% 0px;
    display: -webkit-inline-flex;
    display: block;
    width: 100%;
  }
}
.gridView {
  .userListing {
    margin: 0px 2% 2% 0px;
    display: inline-flex;
    width: 49%;
    &:nth-child(2n + 2) {
      margin-right: 0 !important;
    }
  }
}
.modal-body {
  .userListing {
    .listContent {
      overflow: hidden;
    }
  }
}
.gridView {
  .userListing{
    .listContent{
      .infoWrapper{
        .nameWrapper {
          max-width: 30%;
        }
      }
    }
  }
}
.userListing {
  .listContent {
    padding: 15px;
    border: none;
    border-radius: 5px !important;
    background-color: #fff;
    transition: all 0.4s ease-in-out 0s;
    cursor: pointer;
    position: relative;
    // overflow: hidden;
    width: 100%;
    border: 1px solid transparent;
    box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
      0 2px 5px 1px rgba(69, 65, 78, 0.04);
    &:hover {
      transition: all 0.4s ease-in-out 0s;
      transform: scale(1.025);
      .userImage {
        img {
          transform: scale(1.05);
          transition: all 0.4s ease-in-out 0s;
        }
      }
      .onHoverAction {
        right: 0px;
        box-shadow: -1px 0px 5px 1px rgba(69, 65, 78, 0.08),
          -1px 0px 5px 1px rgba(69, 65, 78, 0.04);
        transition: all 0.4s ease-in-out 0s;
      }
    }

    .onHoverAction {
      position: absolute;
      transition: all 0.4s ease-in-out 0s;
      background-color: #fff;
      right: -100%;
      top: 0;
      height: 100%;
      padding: 15px;
      border-radius: 10px;
    }
    .imageWrapper {
      width: 100px;
      height: 100%;
      .userImage {
        width: 100%;
        height: 100%;
        img {
          transition: all 0.4s ease-in-out 0s;
        }
      }
    }
    .infoWrapper {
      width: calc(100% - 100px);
      margin-left: 15px;
      .nameWrapper {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70%;
        margin-bottom: 12px;
        a {
          font-size: 14px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.2;
          margin-bottom: 5px;
        }
        h3 {
          font-size: 13px;
          margin: 0;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .categoryWrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      p {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .skillsWrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      p {
        font-size: 12px;
        color: #808080;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .contactWrapper {
      .filterBtn {
        .btn {
          width: 24px;
          padding: 0px;
          height: 24px;
          font-size: 8px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          position: relative;
          button {
            left: 0;
            top: 0;
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .fullWidth {
      width: 100%;
      .filterBtn {
        width: 100%;
        .btn {
          width: 100%;
          font-size: 12px;
        }
      }
    }
    .rateWrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      p {
        margin: 0;
        text-align: right;
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .statusWrapper {
      position: absolute;
      right: 0;
      top: 15px;
      border-radius: 3px 0px 0px 3px;
      width: 65px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;
      padding: 2px;
      p {
        margin: 0;
        color: #fff;
        font-size: 11px;
        line-height: 1.2;
      }
    }
    .statusWrapper2 {
      padding: 2px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        color: #fff;
        font-size: 11px;
        line-height: 1.2;
      }
    }
    .active {
      background: #5fdba7;
    }
    .inActive {
      background: #bbb;
    }
    .approved {
      background: #00d014;
    }
    .pending {
      background: #ffb70f;
    }
    .rejected {
      background: #ff3739;
    }
    .archived {
      background: #808080;
    }
    .blackListed {
      background: #000;
    }
  }

  .actionWrapper {
    .filterBtn {
      .btn {
        width: 20px;
        height: 20px;
        padding: 0;
        font-size: 8px;
        border: transparent;
      }
    }
  }
  .feedbackIcon {
    cursor: pointer;
  }
  .feedbackBtn {
    border: 1px solid transparent;
    width: 24px;
    padding: 0px;
    height: 24px;
    font-size: 8px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;
  }
  .actionCheckBox {
    width: 24px;
    height: 24px;
    position: relative;
    .checkBox {
      position: initial;
      padding-left: 0px !important;
      width: 24px;
      height: 24px;
      .checkmark {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        &:after {
          left: 8px;
          top: 4px;
          width: 6px;
          height: 12px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }
}

.detailViewStatus {
  width: 100px;
  height: 28px;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  span {
    margin: 0;
    color: #fff;
    font-size: 12px;
    line-height: 1.2;
  }
  &.active {
    background: #5fdba7;
  }
  &.approved {
    background: #00d014;
  }
  &.pending {
    background: #ffb70f;
  }
  &.rejected,
  &.trashed {
    background: #ff3739;
  }
  &.archived {
    background: #808080;
  }
}

.borderBottomLight {
  border-bottom: 1px solid #ced4da;
}
.borderTopLight {
  border-top: 1px solid #ced4da;
}

.filterDropdown {
  .dropdown-menu {
    box-shadow: 0 1px 10px -1px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 5px;
    .dropdown-item {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.87);
      padding: 5px 15px;
      outline: none !important;
      &.disabled {
        color: #fff;
        background: #ced4da !important;
      }
      svg {
        margin-right: 5px;
      }
      a {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.87);
        padding: 5px 15px;
        outline: none !important;
        padding: 0;
        background-color: transparent;
      }
      button {
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
  }
}
.dropdown-item:focus{
  a {
    color: #fff !important;
  }
  button{
    color: #fff !important;
  }
}
.custRadio {
  label {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0 !important;
    top: 2px;
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sp-col-header {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  margin: 0;
  .sp-col {
    flex: 1;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    margin: 0px 5px;
    height: 100%;
    &.top-col {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: flex;
    }
    .sp-col-text {
      max-width: 100%;
      color: #333;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    b {
      margin-left: 5px;
      font-weight: 600;
    }
    .sp-taskWrapper {
      &:last-child {
        margin-bottom: 0;
      }
      .sp-task {
        background-color: #fff;
        display: block;
        padding: 10px;
        border-radius: 5px;
        transition: 0.2s;
        box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &:hover {
          .onHoverAction {
            top: 0px;
            box-shadow: 1px 0px 5px 1px rgba(69, 65, 78, 0.08),
              1px 0px 5px 1px rgba(69, 65, 78, 0.04);
            transition: all 0.4s ease-in-out 0s;
          }
        }
        .onHoverAction {
          position: absolute;
          transition: all 0.4s ease-in-out 0s;
          background-color: #fff;
          top: -100%;
          left: 0;
          right: 0;
          padding: 5px;
          height: calc(100% - 37px);
        }
        .daysLeftWrapper {
          font-size: 10px;
          padding: 1px ​2px;
          width: 60px;
          color: #fff;
          border-radius: 3px;
          text-align: center;
          &.new {
            background-color: #808080;
          }
          &.active {
            background-color: #00d014;
          }
          &.low {
            background-color: #0065ff;
          }
          &.high {
            background-color: #ffab00;
          }
          &.urgent {
            background-color: #ff5630;
            animation: urgent-pulse 1s infinite;
            animation-play-state: running;
            box-shadow: 0px 0px 1px 1px rgba(255, 86, 48, 0.3);
          }
          &.overdue {
            background-color: #ff3730;
          }
        }
        .clientWrapper {
          max-width: 70%;
          margin-right: 5px;
          span {
            width: 18px;
            height: 18px;
            line-height: 18px;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            font-size: 10px;
            display: block;
            margin-right: 2px;
            &.color1 {
              background-color: #673ab7;
            }
            &.color2 {
              background-color: #40af2a;
            }
            &.color3 {
              background-color: #b73aad;
            }
            &.color4 {
              background-color: #df6017;
            }
            &.color5 {
              background-color: #b73a3a;
            }
            &.color6 {
              background-color: #3a94b7;
            }
          }
          .clientName {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
            h2 {
              margin: 0;
              font-size: 12px;
              font-weight: 600;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .areaWrapper {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          h3 {
            font-size: 11px;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            b {
              margin: 0;
            }
          }
        }
        .idWrapper {
          width: calc(100% - 60px);
          text-align: right;
          .linkBtn {
            background-color: transparent !important;
            border: none !important;
            padding: 0;
            font-size: 11px;
            vertical-align: initial;
            text-overflow: ellipsis;
            max-width: 80%;
            flex-flow: nowrap;
            overflow: hidden;
            white-space: nowrap;
            &:hover {
              background-color: transparent !important;
              border: none !important;
              text-decoration: underline;
            }
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active,
            .dropdown-toggle {
              background-color: transparent !important;
              border: none !important;
            }
          }
        }
        .titleWrapper {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          height: 3em;
          h4 {
            color: #333;
            font-size: 12px;
            font-weight: 400;
            display: block;
            box-sizing: content-box;
            line-height: 1.42857143;
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
          }
        }
        .onHoverAction {
          span {
            // display: flex;
            align-items: center;
            font-size: 11px;
            sup {
              left: 1px;
              font-weight: 600;
              top: -1px;
              font-size: 9px;
            }
          }
          svg {
            margin-right: 2px;
          }
        }
        .dateWrapper {
          padding-bottom: 5px;
          display: flex;
          align-items: center;
        }

        .timeWrapper {
          // display: flex;
          // align-items: center;
          // padding-bottom: 5px;
          // justify-content: space-between;
          .localTime,
          .pkTime {
            // display: flex;
            // align-items: center;
            padding-bottom: 5px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            .location {
              margin-right: 5px;
              font-weight: 600;
            }
          }
        }
        .addressWrapper {
          // display: flex;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }

        .addressWrapper span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .sp-taskDetails {
        border-top: 1px solid #dadada;
      }
    }
  }
}

.emptySprint {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.emptySprint h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 15px 0px;
  color: #888;
}

.emptySprint a {
  font-size: 16px;
  display: inline-block;
  color: #888;
  transition: 0.2s;
}

.emptySprint a:hover {
  color: #333;
  text-decoration: underline;
}

.emptySprint span {
  font-size: 16px;
  display: inline-block;
  color: #333;
}
.cust-modal {
  .modal-content {
    background-color: transparent;
    border: none;
    .modal-header,
    .modal-body,
    .modal-footer {
      background-color: #fff;
      border-radius: 10px;
    }
    .modal-header {
      padding: 1rem 20px;
      margin-bottom: 10px;
      justify-content: start;
      button.close {
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        margin: 0;
      }
    }
    .modal-footer {
      margin-top: 10px;
      justify-content: space-between;
      .infoWrapper {
        label {
          padding: 6px;
          background-color: #f0f0f0;
          margin-right: 10px;
          border-radius: 2px;
        }
      }
    }
    .modal-body {
      padding: 20px;
      .formWrapper {
        height: calc(100vh - 300px);
        flex: 4;
      }
      .formWrapper2 {
        height: calc(100vh - 250px);
      }
      .formWrapper3 {
        height: calc(100vh - 340px);
      }
      .rightSideWrapper {
        height: calc(100vh - 300px);
        // overflow: hidden !important;
        flex: 1;
      }
      .nav-tabs {
        .nav-item {
          width: 25%;
        }
      }
      .listViewWrapper {
        width: auto;
        position: relative;
        left: auto;
        transition: left 0.5s ease-in;
        flex: 4;
        .searchWrapper {
          p {
            margin: 0;
          }
        }
        .contactWrapper {
          .filterBtn {
            .btn {
              font-size: 11px;
              padding: 0px 5px;
            }
          }
        }
      }
      .mapViewWrapper {
        position: relative;
        background-color: #f9f9f9;
        overflow: hidden;
        width: 100%;
        margin: auto;
        transition: margin 0.5s ease-in;
        flex: 2;
      }
      .totalTimeLabel {
        padding: 10px;
        color: #fff;
        font-weight: 500;
        width: 100%;
        display: block;
        text-align: center;
        border-radius: 3px;
        font-size: 14px;
      }

      .userImage {
        width: 100px;
        height: 100px;
      }
      .pocImage {
        width: 70px;
        height: 70px;
      }
      h2 {
        font-size: 22px;
      }
      h2.bg-color {
        color: #fff;
        border-radius: 3px;
        padding: 6px;
        font-size: 13px;
      }
      .colList li {
        margin: 0px 1% 1% 0px;
        display: -webkit-inline-flex;
        display: inline-flex;
        width: 49%;
        .form-check-label {
          width: 100%;
        }
      }
      .gridView,
      .listView {
        height: calc(100% - 45px);
      }
      .empDetailTable {
        th,
        td {
          padding: 3px !important;
        }
      }
    }
  }
}

.confirmation-modal {
  .modal-content {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    .iconBox {
      svg {
        width: 40px;
        height: 40px;
        color: #808080;
      }
    }
    p {
      margin: 0;
    }
    .modal-header,
    .modal-footer,
    .modal-body {
      padding: 20px;
    }
  }
}

@keyframes urgent-pulse {
  0% {
    background-color: #ff5630;
    box-shadow: 0 0 0 0px rgba(255, 86, 48, 0.3);
  }
  50% {
    background-color: #ff3e30;
    box-shadow: 0 0 0 5px rgba(255, 62, 48, 0.2);
  }
  100% {
    background-color: #e62121;
    box-shadow: 0 0 0 10px rgba(230, 33, 33, 0.1);
  }
}
.loginApprovalsTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 15%;
      }
      &:nth-child(6) {
        width: 15%;
      }
      &:nth-child(7) {
        width: 10%;
      }
    }
  }
}
.usersDetailLoginHistoryTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
}
.permissionTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      &:nth-child(3) {
        width: 10%;
      }
    }
  }
}
.rolesTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 75%;
      }
      &:nth-child(3) {
        width: 10%;
      }
    }
  }
}
.usersTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
    }
  }
}
.partnersTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 15%;
      }
    }
  }
}

.myTicketsTable{
  table{    
    td,  th{  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
    max-width: 68px;
    font-size: 12px;
    } 
  }
}
.myTicketsTable table th:before, 
.myTicketsTable table th:after {
  right: 0em !important;
}
.myTicketsTable{
  table {
    th,
    td {
      &:nth-child(1) {
        width: 7.5%;
      }
      &:nth-child(2) {
        width: 7.5%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 7.5%;
      }
      &:nth-child(8) {
        width: 7.5%;
      }
      &:nth-child(9) {
        width: 10%;
        white-space: normal;
      }
      &:nth-child(10) {
        width: 10%;        
        white-space: normal;
      }
      &:nth-child(11) {
        width: 10%;
      }
    }
  }
}
.recruiterTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 10%;
      }
    }
  }
}
.projectsTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 16%;
      }
      &:nth-child(2) {
        width: 16%;
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 16%;
      }
      &:nth-child(5) {
        width: 16%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
    }
  }
}
.citiesTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 40%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 10%;
      }
    }
  }
}
.countriesTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
    }
  }
}
.generalSettingsTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 80%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 10%;
      }
    }
  }
}
.sitesTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 15%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
    }
  }
}
.recTechTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
    }
  }
}
.techTable {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 20%;
      }
    }
  }
}

ul.techList {
  margin: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 0;
  li {
    list-style-type: none;
    display: inline-block;
    margin-left: -10px;
    position: relative;
    transition: 0.2s;
    top: 0px;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      top: -2px;
      transition: 0.2s;
      z-index: 2;
    }
    .techImage {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #f7f7f7;
      border: 2px solid #cbcbcb;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: block;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.textOverFlow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text3Lines {
  -webkit-line-clamp: 3;
}
.detailDescription {
  max-height: 55px;
  overflow: auto;
}
.timeBox {
  .css-1wy0on6,
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
}
.feedbackIcon {
  cursor: pointer;
}
.feedbackPopover {
  .popover-header {
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
  }
  .popover-body {
    padding: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
  }
  .bs-popover-bottom > .arrow,
  .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 0px);
  }
  .popover-inner {
    border: none;
    border-radius: 5px !important;
    background-color: #fff;
    transition: all 0.4s ease-in-out 0s;
    box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
      0 2px 5px 1px rgba(69, 65, 78, 0.04);
  }
}
.docWrapper {
  .colList {
    li {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      .docContent {
        display: block;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        color: rgba(0, 0, 0, 0.87);
        font-size: 13px;
        text-decoration: none;
        transition: 0.3s ease-in;
        overflow: hidden;
        position: relative;
        background-color: #fff;
        z-index: 2;
        cursor: pointer;
        &:hover {
          transition: 0.3s ease-out;
          background-color: #f9f9f9;
        }
        .docName {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          width: 90%;
          span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .docAction {
          width: 10%;
          a {
            border: none;
            padding: 0;
            border-radius: 0;
            .btn {
              font-size: 12px;
              box-shadow: none !important;
              outline: none !important;
              width: 24px;
              height: 24px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
.upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.95;
  }
  input[type="file"] {
    display: input-block;
    width: 100%;
    height: 30px;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
  }
  .fileName {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
  }
}

.uploadButton {
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  width: auto;
  padding: 0 15px;
  box-sizing: content-box;
}

.rolesList {
  ul {
    padding-left: 0;
    display: inline-flex;
    li {
      margin-right: 5px;
      border-radius: 3px;
      font-size: 11px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      span {
        padding: 3px 5px;
      }
    }
  }
}
ol {
  padding-left: 15px;
  margin-bottom: 0;
  list-style-type: decimal;
  li {
    list-style: decimal;
    padding-left: 5px;
  }
}

.toggle-switch {
  height: 25px;
  width: 100%;
  border: 1px solid #dee2e6;
  display: flex;
  overflow: hidden;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border-radius: 3px;
  font-size: 11px;
  cursor: pointer;
  &:after,
  &:before {
    height: 25px;
    width: 100%;
    display: block;
    line-height: 25px;
    text-align: center;
    position: absolute;
    transition: left 0.5s, right 0.5s;
  }
  &::after {
    left: 0;
  }
  &::before {
    right: 100%;
  }

  &:checked {
    &::after {
      left: 100%;
    }
    &::before {
      right: 0;
    }
  }
  &.switch-on-off {
    &::after {
      background-color: #5fdba7;
      content: "Approve";
      color: #fff;
    }
    &::before {
      content: "Un-Approve";
      background-color: #808080;
      color: #fff;
    }
  }
}

.toggle-switch-label {
  line-height: 25px;
  vertical-align: top;
  margin-top: 4px;
}

.bounce {
  border-color: #e82b2b !important;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    transition-timing-function: ease-in;
  }

  37% {
    transform: translateX(5px);
    transition-timing-function: ease-out;
  }

  55% {
    transform: translateX(-5px);
    transition-timing-function: ease-in;
  }

  73% {
    transform: translateX(4px);
    transition-timing-function: ease-out;
  }

  82% {
    transform: translateX(-4px);
    transition-timing-function: ease-in;
  }

  91% {
    transform: translateX(2px);
    transition-timing-function: ease-out;
  }

  96% {
    transform: translateX(-2px);
    transition-timing-function: ease-in;
  }

  100% {
    transform: translateX(0px);
    transition-timing-function: ease-in;
  }
}

.bounce .css-yk16xz-control {
  border-color: #e82b2b !important;
}

.noData {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
  text-align: center;
  p {
    font-size: 16px;
    margin: 0;
    color: #808080;
    margin-top: 5px;
  }
  svg {
    color: #808080;
  }
}
.themeColorList {
  li {
    margin: 1%;
    display: inline-flex;
    width: 23%;
    &:hover {
      .themeColorBox {
        box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
          0 2px 5px 1px rgba(69, 65, 78, 0.04);
        transition: 0.4s ease-in-out;
        .themeImg {
          img {
            transform: scale(1.05);
            transition: 0.7s ease-in-out;
            transform-origin: top left;
          }
        }
      }
    }
    .themeColorBox {
      width: 100%;
      height: 150px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: none;
      transition: 0.2s ease-in-out;
      .themeImg {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          transition: 0.5s ease-in-out;
          transform-origin: top left;
        }
      }
      .custRadio {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        label {
          width: 100%;
          height: 100%;
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          left: 5px;
          top: 5px;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          top: 9px;
          left: 9px;
        }
      }
    }
  }
}
.themeColorCard {
  h2.bg-color {
    color: #fff;
    border-radius: 3px;
    padding: 6px;
    font-size: 13px;
  }
}

.tooltip {
  background-color: transparent !important;
  border: none !important;
  font-size: 12px !important;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 0px);
}
.filterBtn {
  .btn {
    position: relative;
    button {
      left: 0;
      top: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}


.disable-link{
  pointer-events: none; 
  cursor: default;
}

  .notesWrapper {
  padding: 15px 8px;
  .notesListing {
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
      0 2px 5px 1px rgba(69, 65, 78, 0.04);
    .userName {
      font-size: 14px;
      font-weight: 500;
    }
    .notesText {
      color: #353535;
    }
    .notesTime {
      font-size: 12px;
      color: #808080;
    }
  }
}
.notesFiled .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.titleBoxwithBtn {
  width: 100%;
  background-color: #009184;
  border-radius: 3px;
  padding: 10px;
  h2 {
    color: #fff;
    font-size: 13px !important;
  }
  .currencyDropdown {
    .css-1pahdxg-control,
    .css-yk16xz-control {
      min-height: 34px;
    }
  }
}
.hoverMarker {
  transition: all 0.4s ease-in-out 0s;
  box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08),
    0 2px 5px 1px rgba(69, 65, 78, 0.04);
    transform: scale(1.025);
  .userImage {
    img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      transition: all 0.4s ease-in-out 0s;
    }
  }
}
.techActionDrop {
    position: absolute;
    right: 15px;
    top: 15px; 
    display: flex;
    .btn {
      width: 24px;
      height: 24px;
      padding: 0;
  }
}

.userProfileIcon {
    background-color: rgba(0, 208, 191, 0.15);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    text-align: center;
    padding: 0 !important;
}

.pac-container {
  z-index: 999999;
}


.wordBreak{
  word-break: break-all;
}

.rolesList2{
  table {
    td {
      vertical-align: top;
    }
  }
  .rolesList{
    ul {
      display: inline-block;
      li {
        display: inline-block;
      }
    }
  }
}
.csvExport a {
  display: flex;
  align-items: center;
}

.tkt-top-right {
  display: flex;
  align-items: center;
  justify-content: start;
}
.tkt-top-right .checkmark {
  top: 2px;
}
// .reportsTable table th:nth-child(3), 
// .reportsTable table td:nth-child(3), 
// .reportsTable table th:nth-child(4), 
// .reportsTable table td:nth-child(4), 
// .reportsTable table th:nth-child(5), 
// .reportsTable table td:nth-child(5), 
// .reportsTable table th:nth-child(6), 
// .reportsTable table td:nth-child(6) {
//   width: 5%;
// }

.searchField input.pac-target-input {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  box-shadow: none !important;
  outline: none !important;
  min-width: 28px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.545rem + 2px);
  padding: 5px;
  padding-left: 30px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  border-radius: 5px 0px 0px 5px;
}
.searchField{
  position: relative;
}
.searchField .searchIcon {
  background-color: #ced4da;
  width: 25px;
  height: 26.72px;
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 3px 0 0 3px;
}
.searchField .searchIcon svg {
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.filterBox .radiusFilter .css-1pahdxg-control,
.filterBox .radiusFilter .css-yk16xz-control {
  min-height: 28.72px !important;
  border-radius: 0px 5px 5px 0px;
  border-left: 0;
}
.filterBox .radiusFilter .css-b8ldur-Input {
  padding-bottom: 1px;
  padding-top: 1px;
}
.filterBox .radiusFilter .css-g1d714-ValueContainer {
  padding: 0px 6px !important;
}

.dashboard-page-scroll {
    height: calc(100vh - 77px);
    padding-right: 15px;
    overflow:auto;
}

.pendingReportTable{
  height: calc(100vh - 427px);
  overflow: overlay;
}

.pendingReportTable{
  table{
    th , td{
      font-size:11px;      
    }
  }
}