.cr-header {
  // margin-bottom: $spacer * .5;
  // border-bottom: $border-width solid $border-color;
  border-bottom: none;
  box-shadow: 0 1px 10px -1px rgba(0,0,0,.2);

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }
  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
 }
 .navbar{
    padding: 0.3rem 1rem !important;
 }
 

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
}
