// colors

// .bg-gradient-theme {
//   background: linear-gradient(to bottom, theme-color("darkGrey"), theme-color("lightGrey"));
// }
// .bg-gradient-theme-top {
//   background: linear-gradient(to top, theme-color("darkGrey"), theme-color("lightGrey"));
// }
// .bg-gradient-theme-left {
//   background: linear-gradient(to left, theme-color("darkGrey"), theme-color("lightGrey"));
// }
// .bg-gradient-theme-right {
//   background: linear-gradient(to right, theme-color("darkGrey"), theme-color("lightGrey"));
// }

//  ===BLACK COLOR SCHEME====

.theme-black {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("blackish-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("blackish-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("blackish-secondary");
  }
  .btn-secondary {
    border-color: theme-color("blackish-primary");
    background: theme-color("blackish-primary");
    &:hover {
      border-color: theme-color("blackish-secondary");
      background: theme-color("blackish-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("blackish-secondary") !important;
      background: theme-color("blackish-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("blackish-primary");
    border-color: theme-color("blackish-primary");
    &:hover {
      border-color: theme-color("blackish-secondary");
      background: theme-color("blackish-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("blackish-secondary") !important;
      background: theme-color("blackish-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("blackish-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("blackish-primary");
      background: linear-gradient(
        0deg,
        theme-color("blackish-light"),
        theme-color("blackish-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("blackish-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("blackish-primary") !important;
    }
    .checkmark {
      color: theme-color("blackish-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("blackish-primary");
    background: theme-color("blackish-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("blackish-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("blackish-light");
        border-bottom-color: theme-color("blackish-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("blackish-primary") !important;
          color: theme-color("blackish-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("blackish-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("blackish-secondary") !important;
        }
        .badge {
          background: theme-color("blackish-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("blackish-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("blackish-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("blackish-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("blackish-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("blackish-primary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("blackish-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("blackish-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("blackish-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("blackish-secondary");
    box-shadow: 0 0 1px 0px theme-color("blackish-secondary");
    background: theme-color("blackish-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("blackish-primary");
    border-bottom: 1px solid theme-color("blackish-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("blackish-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("blackish-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("blackish-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("blackish-primary");
    background: theme-color("blackish-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("blackish-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("blackish-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("blackish-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("blackish-extra-light");
  }
  .userImage {
    border-color: theme-color("blackish-light");
    background: theme-color("blackish-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("blackish-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("blackish-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("blackish-extra-light");
    }
  }
  .progress {
    background: theme-color("blackish-extra-light");
    .progress-bar{
      background: theme-color("blackish-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("blackish-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("blackish-primary");
      &:hover {
        color: theme-color("blackish-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("blackish-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("blackish-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("blackish-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("blackish-primary");
        background: theme-color("blackish-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("blackish-primary");
        }
        button{
          color: theme-color("blackish-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("blackish-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("blackish-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("blackish-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("blackish-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("blackish-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("blackish-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("blackish-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("blackish-primary");
      }
    }
    .location{
      color: theme-color("blackish-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("blackish-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("blackish-primary") !important;
    color: theme-color("blackish-primary");
    &:hover {
      border-color: theme-color("blackish-secondary") !important;
      background: theme-color("blackish-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("blackish-secondary");
    :hover {
      color: theme-color("blackish-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("blackish-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("blackish-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("blackish-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("blackish-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("blackish-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("blackish-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("blackish-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("blackish-primary") !important;
  }
  .text-themeColor{
    color: theme-color("blackish-primary");
  }
  .table td a {
    color: theme-color("blackish-primary");
  }
}

//  ===BLUE COLOR SCHEME====

.theme-blue {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("blue-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("blue-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("blue-secondary");
  }
  .btn-secondary {
    border-color: theme-color("blue-primary");
    background: theme-color("blue-primary");
    &:hover {
      border-color: theme-color("blue-secondary");
      background: theme-color("blue-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("blue-secondary") !important;
      background: theme-color("blue-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("blue-primary");
    border-color: theme-color("blue-primary");
    &:hover {
      border-color: theme-color("blue-secondary");
      background: theme-color("blue-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("blue-secondary") !important;
      background: theme-color("blue-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("blue-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("blue-primary");
      background: linear-gradient(
        0deg,
        theme-color("blue-light"),
        theme-color("blue-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("blue-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("blue-primary") !important;
    }
    .checkmark {
      color: theme-color("blue-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("blue-primary");
    background: theme-color("blue-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("blue-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("blue-light");
        border-bottom-color: theme-color("blue-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("blue-primary") !important;
          color: theme-color("blue-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("blue-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("blue-secondary") !important;
        }
        .badge {
          background: theme-color("blue-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("blue-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("blue-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("blue-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("blue-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("blue-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("blue-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("blue-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("blue-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("blue-secondary");
    box-shadow: 0 0 1px 0px theme-color("blue-secondary");
    background: theme-color("blue-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("blue-primary");
    border-bottom: 1px solid theme-color("blue-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("blue-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("blue-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("blue-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("blue-primary");
    background: theme-color("blue-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("blue-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("blue-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("blue-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("blue-extra-light");
  }
  .userImage {
    border-color: theme-color("blue-light");
    background: theme-color("blue-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("blue-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("blue-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("blue-extra-light");
    }
  }
  .progress {
    background: theme-color("blue-extra-light");
    .progress-bar{
      background: theme-color("blue-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("blue-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("blue-primary");
      &:hover {
        color: theme-color("blue-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("blue-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("blue-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("blue-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("blue-primary");
        background: theme-color("blue-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("blue-primary");
        }
        button{
          color: theme-color("blue-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("blue-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("blue-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("blue-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("blue-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("blue-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("blue-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("blue-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("blue-primary");
      }
    }
    .location{
      color: theme-color("blue-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("blue-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("blue-primary") !important;
    color: theme-color("blue-primary");
    &:hover {
      border-color: theme-color("blue-secondary") !important;
      background: theme-color("blue-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("blue-secondary");
    :hover {
      color: theme-color("blue-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("blue-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("blue-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("blue-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("blue-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("blue-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("blue-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("blue-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("blue-primary") !important;
  }
  .text-themeColor{
    color: theme-color("blue-primary");
  }
  .table td a {
    color: theme-color("blue-primary");
  }
}

//  ===PURPLE COLOR SCHEME====

.theme-purple {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("purple-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("purple-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("purple-secondary");
  }
  .btn-secondary {
    border-color: theme-color("purple-primary");
    background: theme-color("purple-primary");
    &:hover {
      border-color: theme-color("purple-secondary");
      background: theme-color("purple-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("purple-secondary") !important;
      background: theme-color("purple-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("purple-primary");
    border-color: theme-color("purple-primary");
    &:hover {
      border-color: theme-color("purple-secondary");
      background: theme-color("purple-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("purple-secondary") !important;
      background: theme-color("purple-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("purple-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("purple-primary");
      background: linear-gradient(
        0deg,
        theme-color("purple-light"),
        theme-color("purple-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("purple-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("purple-primary") !important;
    }
    .checkmark {
      color: theme-color("purple-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("purple-primary");
    background: theme-color("purple-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("purple-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("purple-light");
        border-bottom-color: theme-color("purple-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("purple-primary") !important;
          color: theme-color("purple-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("purple-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("purple-secondary") !important;
        }
        .badge {
          background: theme-color("purple-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("purple-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("purple-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("purple-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("purple-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("purple-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("purple-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("purple-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("purple-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("purple-secondary");
    box-shadow: 0 0 1px 0px theme-color("purple-secondary");
    background: theme-color("purple-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("purple-primary");
    border-bottom: 1px solid theme-color("purple-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("purple-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("purple-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("purple-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("purple-primary");
    background: theme-color("purple-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("purple-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("purple-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("purple-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("purple-extra-light");
  }
  .userImage {
    border-color: theme-color("purple-light");
    background: theme-color("purple-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("purple-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("purple-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("purple-extra-light");
    }
  }
  .progress {
    background: theme-color("purple-extra-light");
    .progress-bar{
      background: theme-color("purple-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("purple-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("purple-primary");
      &:hover {
        color: theme-color("purple-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("purple-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("purple-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("purple-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("purple-primary");
        background: theme-color("purple-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("purple-primary");
        }
        button{
          color: theme-color("purple-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("purple-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("purple-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("purple-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("purple-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("purple-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("purple-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("purple-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("purple-primary");
      }
    }
    .location{
      color: theme-color("purple-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("purple-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("purple-primary") !important;
    color: theme-color("purple-primary");
    &:hover {
      border-color: theme-color("purple-secondary") !important;
      background: theme-color("purple-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("purple-secondary");
    :hover {
      color: theme-color("purple-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("purple-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("purple-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("purple-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("purple-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("purple-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("purple-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("purple-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("purple-primary") !important;
  }
  .text-themeColor{
    color: theme-color("purple-primary");
  }
  .table td a {
    color: theme-color("purple-primary");
  }
}

//  ===PINK COLOR SCHEME====

.theme-pink {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("pink-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("pink-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("pink-secondary");
  }
  .btn-secondary {
    border-color: theme-color("pink-primary");
    background: theme-color("pink-primary");
    &:hover {
      border-color: theme-color("pink-secondary");
      background: theme-color("pink-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("pink-secondary") !important;
      background: theme-color("pink-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("pink-primary");
    border-color: theme-color("pink-primary");
    &:hover {
      border-color: theme-color("pink-secondary");
      background: theme-color("pink-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("pink-secondary") !important;
      background: theme-color("pink-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("pink-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("pink-primary");
      background: linear-gradient(
        0deg,
        theme-color("pink-light"),
        theme-color("pink-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("pink-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("pink-primary") !important;
    }
    .checkmark {
      color: theme-color("pink-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("pink-primary");
    background: theme-color("pink-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("pink-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("pink-light");
        border-bottom-color: theme-color("pink-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("pink-primary") !important;
          color: theme-color("pink-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("pink-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("pink-secondary") !important;
        }
        .badge {
          background: theme-color("pink-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("pink-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("pink-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("pink-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("pink-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("pink-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("pink-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("pink-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("pink-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("pink-secondary");
    box-shadow: 0 0 1px 0px theme-color("pink-secondary");
    background: theme-color("pink-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("pink-primary");
    border-bottom: 1px solid theme-color("pink-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("pink-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("pink-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("pink-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("pink-primary");
    background: theme-color("pink-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("pink-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("pink-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("pink-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("pink-extra-light");
  }
  .userImage {
    border-color: theme-color("pink-light");
    background: theme-color("pink-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("pink-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("pink-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("pink-extra-light");
    }
  }
  .progress {
    background: theme-color("pink-extra-light");
    .progress-bar{
      background: theme-color("pink-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("pink-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("pink-primary");
      &:hover {
        color: theme-color("pink-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("pink-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("pink-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("pink-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("pink-primary");
        background: theme-color("pink-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("pink-primary");
        }
        button{
          color: theme-color("pink-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("pink-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("pink-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("pink-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("pink-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("pink-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("pink-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("pink-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("pink-primary");
      }
    }
    .location{
      color: theme-color("pink-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("pink-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("pink-primary") !important;
    color: theme-color("pink-primary");
    &:hover {
      border-color: theme-color("pink-secondary") !important;
      background: theme-color("pink-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("pink-secondary");
    :hover {
      color: theme-color("pink-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("pink-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("pink-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("pink-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("pink-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("pink-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("pink-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("pink-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("pink-primary") !important;
  }
  .text-themeColor{
    color: theme-color("pink-primary");
  }
  .table td a {
    color: theme-color("pink-primary");
  }
}

//  ===RED COLOR SCHEME====

.theme-red {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("red-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("red-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("red-secondary");
  }
  .btn-secondary {
    border-color: theme-color("red-primary");
    background: theme-color("red-primary");
    &:hover {
      border-color: theme-color("red-secondary");
      background: theme-color("red-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("red-secondary") !important;
      background: theme-color("red-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("red-primary");
    border-color: theme-color("red-primary");
    &:hover {
      border-color: theme-color("red-secondary");
      background: theme-color("red-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("red-secondary") !important;
      background: theme-color("red-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("red-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("red-primary");
      background: linear-gradient(
        0deg,
        theme-color("red-light"),
        theme-color("red-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("red-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("red-primary") !important;
    }
    .checkmark {
      color: theme-color("red-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("red-primary");
    background: theme-color("red-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("red-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("red-light");
        border-bottom-color: theme-color("red-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("red-primary") !important;
          color: theme-color("red-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("red-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("red-secondary") !important;
        }
        .badge {
          background: theme-color("red-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("red-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("red-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("red-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("red-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("red-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("red-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("red-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("red-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("red-secondary");
    box-shadow: 0 0 1px 0px theme-color("red-secondary");
    background: theme-color("red-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("red-primary");
    border-bottom: 1px solid theme-color("red-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("red-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("red-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("red-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("red-primary");
    background: theme-color("red-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("red-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("red-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("red-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("red-extra-light");
  }
  .userImage {
    border-color: theme-color("red-light");
    background: theme-color("red-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("red-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("red-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("red-extra-light");
    }
  }
  .progress {
    background: theme-color("red-extra-light");
    .progress-bar{
      background: theme-color("red-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("red-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("red-primary");
      &:hover {
        color: theme-color("red-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("red-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("red-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("red-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("red-primary");
        background: theme-color("red-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("red-primary");
        }
        button{
          color: theme-color("red-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("red-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("red-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("red-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("red-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("red-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("red-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("red-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("red-primary");
      }
    }
    .location{
      color: theme-color("red-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("red-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("red-primary") !important;
    color: theme-color("red-primary");
    &:hover {
      border-color: theme-color("red-secondary") !important;
      background: theme-color("red-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("red-secondary");
    :hover {
      color: theme-color("red-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("red-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("red-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("red-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("red-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("red-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("red-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("red-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("red-primary") !important;
  }
  .text-themeColor{
    color: theme-color("red-primary");
  }
  .table td a {
    color: theme-color("red-primary");
  }
}

//  ===TEAL COLOR SCHEME====

.theme-teal {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("teal-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("teal-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("teal-secondary");
  }
  .btn-secondary {
    border-color: theme-color("teal-primary");
    background: theme-color("teal-primary");
    &:hover {
      border-color: theme-color("teal-secondary");
      background: theme-color("teal-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("teal-secondary") !important;
      background: theme-color("teal-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("teal-primary");
    border-color: theme-color("teal-primary");
    &:hover {
      border-color: theme-color("teal-secondary");
      background: theme-color("teal-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("teal-secondary") !important;
      background: theme-color("teal-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("teal-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("teal-primary");
      background: linear-gradient(
        0deg,
        theme-color("teal-light"),
        theme-color("teal-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("teal-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("teal-primary") !important;
    }
    .checkmark {
      color: theme-color("teal-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("teal-primary");
    background: theme-color("teal-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("teal-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("teal-light");
        border-bottom-color: theme-color("teal-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("teal-primary") !important;
          color: theme-color("teal-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("teal-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("teal-secondary") !important;
        }
        .badge {
          background: theme-color("teal-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("teal-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("teal-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("teal-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("teal-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("teal-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("teal-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("teal-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("teal-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("teal-secondary");
    box-shadow: 0 0 1px 0px theme-color("teal-secondary");
    background: theme-color("teal-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("teal-primary");
    border-bottom: 1px solid theme-color("teal-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("teal-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("teal-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("teal-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("teal-primary");
    background: theme-color("teal-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("teal-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("teal-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("teal-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("teal-extra-light");
  }
  .userImage {
    border-color: theme-color("teal-light");
    background: theme-color("teal-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("teal-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("teal-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("teal-extra-light");
    }
  }
  .progress {
    background: theme-color("teal-extra-light");
    .progress-bar{
      background: theme-color("teal-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("teal-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("teal-primary");
      &:hover {
        color: theme-color("teal-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("teal-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("teal-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("teal-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("teal-primary");
        background: theme-color("teal-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("teal-primary");
        }
        button{
          color: theme-color("teal-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("teal-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("teal-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("teal-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("teal-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("teal-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("teal-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("teal-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("teal-primary");
      }
    }
    .location{
      color: theme-color("teal-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("teal-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("teal-primary") !important;
    color: theme-color("teal-primary");
    &:hover {
      border-color: theme-color("teal-secondary") !important;
      background: theme-color("teal-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("teal-secondary");
    :hover {
      color: theme-color("teal-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("teal-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("teal-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("teal-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("teal-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("teal-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("teal-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("teal-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("teal-primary") !important;
  }
  .text-themeColor{
    color: theme-color("teal-primary");
  }
  .table td a {
    color: theme-color("teal-primary");
  }
}

//  ===GREEN COLOR SCHEME====

.theme-green {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("green-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("green-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("green-secondary");
  }
  .btn-secondary {
    border-color: theme-color("green-primary");
    background: theme-color("green-primary");
    &:hover {
      border-color: theme-color("green-secondary");
      background: theme-color("green-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("green-secondary") !important;
      background: theme-color("green-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("green-primary");
    border-color: theme-color("green-primary");
    &:hover {
      border-color: theme-color("green-secondary");
      background: theme-color("green-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("green-secondary") !important;
      background: theme-color("green-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("green-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("green-primary");
      background: linear-gradient(
        0deg,
        theme-color("green-light"),
        theme-color("green-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("green-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("green-primary") !important;
    }
    .checkmark {
      color: theme-color("green-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("green-primary");
    background: theme-color("green-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("green-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("green-light");
        border-bottom-color: theme-color("green-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("green-primary") !important;
          color: theme-color("green-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("green-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("green-secondary") !important;
        }
        .badge {
          background: theme-color("green-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("green-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("green-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("green-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("green-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("green-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("green-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("green-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("green-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("green-secondary");
    box-shadow: 0 0 1px 0px theme-color("green-secondary");
    background: theme-color("green-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("green-primary");
    border-bottom: 1px solid theme-color("green-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("green-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("green-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("green-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("green-primary");
    background: theme-color("green-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("green-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("green-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("green-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("green-extra-light");
  }
  .userImage {
    border-color: theme-color("green-light");
    background: theme-color("green-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("green-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("green-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("green-extra-light");
    }
  }
  .progress {
    background: theme-color("green-extra-light");
    .progress-bar{
      background: theme-color("green-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("green-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("green-primary");
      &:hover {
        color: theme-color("green-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("green-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("green-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("green-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("green-primary");
        background: theme-color("green-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("green-primary");
        }
        button{
          color: theme-color("green-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("green-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("green-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("green-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("green-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("green-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("green-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("green-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("green-primary");
      }
    }
    .location{
      color: theme-color("green-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("green-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("green-primary") !important;
    color: theme-color("green-primary");
    &:hover {
      border-color: theme-color("green-secondary") !important;
      background: theme-color("green-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("green-secondary");
    :hover {
      color: theme-color("green-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("green-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("green-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("green-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("green-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("green-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("green-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("green-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("green-primary") !important;
  }
  .text-themeColor{
    color: theme-color("green-primary");
  }
  .table td a {
    color: theme-color("green-primary");
  }
}

//  ===SEA GREEN COLOR SCHEME====

.theme-sea-green {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("sea-green-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("sea-green-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("sea-green-secondary");
  }
  .btn-secondary {
    border-color: theme-color("sea-green-primary");
    background: theme-color("sea-green-primary");
    &:hover {
      border-color: theme-color("sea-green-secondary");
      background: theme-color("sea-green-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("sea-green-secondary") !important;
      background: theme-color("sea-green-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("sea-green-primary");
    border-color: theme-color("sea-green-primary");
    &:hover {
      border-color: theme-color("sea-green-secondary");
      background: theme-color("sea-green-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("sea-green-secondary") !important;
      background: theme-color("sea-green-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("sea-green-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("sea-green-primary");
      background: linear-gradient(
        0deg,
        theme-color("sea-green-light"),
        theme-color("sea-green-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("sea-green-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("sea-green-primary") !important;
    }
    .checkmark {
      color: theme-color("sea-green-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("sea-green-primary");
    background: theme-color("sea-green-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("sea-green-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("sea-green-light");
        border-bottom-color: theme-color("sea-green-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("sea-green-primary") !important;
          color: theme-color("sea-green-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("sea-green-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("sea-green-secondary") !important;
        }
        .badge {
          background: theme-color("sea-green-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("sea-green-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("sea-green-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("sea-green-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("sea-green-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("sea-green-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("sea-green-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("sea-green-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("sea-green-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("sea-green-secondary");
    box-shadow: 0 0 1px 0px theme-color("sea-green-secondary");
    background: theme-color("sea-green-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("sea-green-primary");
    border-bottom: 1px solid theme-color("sea-green-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("sea-green-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("sea-green-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("sea-green-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("sea-green-primary");
    background: theme-color("sea-green-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("sea-green-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("sea-green-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("sea-green-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("sea-green-extra-light");
  }
  .userImage {
    border-color: theme-color("sea-green-light");
    background: theme-color("sea-green-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("sea-green-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("sea-green-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("sea-green-extra-light");
    }
  }
  .progress {
    background: theme-color("sea-green-extra-light");
    .progress-bar{
      background: theme-color("sea-green-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("sea-green-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("sea-green-primary");
      &:hover {
        color: theme-color("sea-green-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("sea-green-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("sea-green-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("sea-green-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("sea-green-primary");
        background: theme-color("sea-green-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("sea-green-primary");
        }
        button{
          color: theme-color("sea-green-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("sea-green-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("sea-green-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("sea-green-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("sea-green-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("sea-green-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("sea-green-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("sea-green-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("sea-green-primary");
      }
    }
    .location{
      color: theme-color("sea-green-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("sea-green-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("sea-green-primary") !important;
    color: theme-color("sea-green-primary");
    &:hover {
      border-color: theme-color("sea-green-secondary") !important;
      background: theme-color("sea-green-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("sea-green-secondary");
    :hover {
      color: theme-color("sea-green-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("sea-green-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("sea-green-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("sea-green-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("sea-green-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("blue-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("blue-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("blue-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("blue-primary") !important;
  }
  .text-themeColor{
    color: theme-color("blue-primary");
  }
  .table td a {
    color: theme-color("blue-primary");
  }
}

//  ===ORANGE COLOR SCHEME====

.theme-orange {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("orange-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("orange-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("orange-secondary");
  }
  .btn-secondary {
    border-color: theme-color("orange-primary");
    background: theme-color("orange-primary");
    &:hover {
      border-color: theme-color("orange-secondary");
      background: theme-color("orange-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("orange-secondary") !important;
      background: theme-color("orange-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("orange-primary");
    border-color: theme-color("orange-primary");
    &:hover {
      border-color: theme-color("orange-secondary");
      background: theme-color("orange-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("orange-secondary") !important;
      background: theme-color("orange-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("orange-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("orange-primary");
      background: linear-gradient(
        0deg,
        theme-color("orange-light"),
        theme-color("orange-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("orange-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("orange-primary") !important;
    }
    .checkmark {
      color: theme-color("orange-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("orange-primary");
    background: theme-color("orange-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("orange-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("orange-light");
        border-bottom-color: theme-color("orange-extra-light") !important;
        border-bottom-color: theme-color("orange-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("orange-primary") !important;
          color: theme-color("orange-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("orange-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("orange-secondary") !important;
        }
        .badge {
          background: theme-color("orange-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("orange-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("orange-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("orange-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("orange-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("orange-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("orange-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("orange-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("orange-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("orange-secondary");
    box-shadow: 0 0 1px 0px theme-color("orange-secondary");
    background: theme-color("orange-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("orange-primary");
    border-bottom: 1px solid theme-color("orange-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("orange-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("orange-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("orange-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("orange-primary");
    background: theme-color("orange-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("orange-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("orange-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("orange-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("orange-extra-light");
  }
  .userImage {
    border-color: theme-color("orange-light");
    background: theme-color("orange-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("orange-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("orange-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("orange-extra-light");
    }
  }
  .progress {
    background: theme-color("orange-extra-light");
    .progress-bar{
      background: theme-color("orange-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("orange-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("orange-primary");
      &:hover {
        color: theme-color("orange-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    .selected {
      border-color: theme-color("orange-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("orange-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("orange-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("orange-primary");
        background: theme-color("orange-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("orange-primary");
        }
        button{
          color: theme-color("orange-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("orange-primary") !important;
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("orange-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("orange-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("orange-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("orange-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("orange-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("orange-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("orange-primary");
      }
    }
    .location{
      color: theme-color("orange-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("orange-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("orange-primary") !important;
    color: theme-color("orange-primary");
    &:hover {
      border-color: theme-color("orange-secondary") !important;
      background: theme-color("orange-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("orange-secondary");
    :hover {
      color: theme-color("orange-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("orange-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("orange-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("orange-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("orange-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("orange-secondary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("orange-secondary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("orange-secondary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("orange-primary") !important;
  }
  .text-themeColor{
    color: theme-color("orange-primary");
  }
  .table td a {
    color: theme-color("orange-primary");
  }
}

//  ===TURQUOISE COLOR SCHEME====

.theme-turquoise {
  ::-webkit-scrollbar-track,
  .modal::-webkit-scrollbar-track {
    background: theme-color("turquoise-extra-light");
  }
  ::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background: theme-color("turquoise-primary");
  }
  ::-webkit-scrollbar-thumb:hover,
  .modal::-webkit-scrollbar-thumb:hover {
    background: theme-color("turquoise-secondary");
  }
  .btn-secondary {
    border-color: theme-color("turquoise-primary");
    background: theme-color("turquoise-primary");
    &:hover {
      border-color: theme-color("turquoise-secondary");
      background: theme-color("turquoise-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("turquoise-secondary") !important;
      background: theme-color("turquoise-secondary") !important;
    }
  }
  .btn-outline-secondary {
    color: theme-color("turquoise-primary");
    border-color: theme-color("turquoise-primary");
    &:hover {
      border-color: theme-color("turquoise-secondary");
      background: theme-color("turquoise-secondary");
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .dropdown-toggle {
      border-color: theme-color("turquoise-secondary") !important;
      background: theme-color("turquoise-secondary") !important;
    }
  }
  .page-item.active {
    .page-link {
      background: theme-color("turquoise-primary");
    }
  }
  .loginLeftWrapper,
  .cr-sidebar {
    &:after {
      background: theme-color("turquoise-primary");
      background: linear-gradient(
        0deg,
        theme-color("turquoise-light"),
        theme-color("turquoise-secondary")
      );
    }
  }
  .bg-color {
    background: theme-color("turquoise-primary");
  }
  .checkBox {
    input:checked ~ .checkmark {
      background: theme-color("turquoise-primary") !important;
    }
    .checkmark {
      color: theme-color("turquoise-primary");
    }
  }
  .css-1n7v3ny-option:focus,
  .css-1n7v3ny-option {
    color: theme-color("turquoise-primary");
    background: theme-color("turquoise-extra-light") !important;
  }
  .css-yt9ioa-option:active,
  .css-9gakcf-option {
    background: theme-color("turquoise-primary") !important;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: theme-color("turquoise-light");
        border-bottom-color: theme-color("turquoise-extra-light") !important;
        border-bottom-color: theme-color("turquoise-extra-light") !important;
        &:hover,
        &.active {
          border-bottom-color: theme-color("turquoise-primary") !important;
          color: theme-color("turquoise-primary");
        }
      }
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: theme-color("turquoise-primary") !important;
        &:hover,
        &:focus {
          color: theme-color("turquoise-secondary") !important;
        }
        .badge {
          background: theme-color("turquoise-primary") !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  ._2oSbenaQCQZPXOD75Sj6nE {
    border-color: theme-color("turquoise-light");
    .YpD9o6H9LydyYmaPWOwt {
      background: theme-color("turquoise-extra-light") !important;
      p.fe6xZVPr1kb0CJksW39zV {
        color: theme-color("turquoise-primary");
      }
    }
  }
  .popover-body {
    .card {
      .card-body {
        background: theme-color("turquoise-primary");
      }
    }
  }
  .notification.notification-info.notification-visible {
    background: theme-color("turquoise-secondary") !important;
  }
  ._23VzqtDiBsdmyddgDcP2FF,
  ._2JJI18xT4B0iIKyuZLMpk9,
  ._1YelFC_KTV9aLijs9ogXcQ {
    fill: theme-color("turquoise-primary");
  }
  ._3L1oDMFmgTzTs8MCYm-pXt {
    fill: theme-color("turquoise-light") !important;
  }
  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-primary-thumb:hover {
    fill: theme-color("turquoise-secondary");
  }

  .kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
    border-color: theme-color("turquoise-secondary");
    box-shadow: 0 0 1px 0px theme-color("turquoise-secondary");
    background: theme-color("turquoise-extra-light");
  }
  .react-datepicker__header {
    background: theme-color("turquoise-primary");
    border-bottom: 1px solid theme-color("turquoise-secondary");
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: theme-color("turquoise-primary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: theme-color("turquoise-secondary") !important;
    color: #fff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: theme-color("turquoise-secondary") !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: theme-color("turquoise-primary");
    background: theme-color("turquoise-extra-light");
  }
  .cardBox {
    .card-header {
      background: theme-color("turquoise-primary") !important;
      .tableBtn.btn-outline-secondary:hover {
        color: theme-color("turquoise-primary") !important;
      }
    }
    .card-sub-header,
    .additionalFields h4 {
      background: theme-color("turquoise-extra-light");
    }
  }
  .salarySummaryBox {
    background: theme-color("turquoise-extra-light");
  }
  .userImage {
    border-color: theme-color("turquoise-light");
    background: theme-color("turquoise-extra-light");
  }
  .titleWrapper {
    h3 {
      color: theme-color("turquoise-primary");
    }
  }
  .slipSalaryDetail {
    thead {
      th {
        background: theme-color("turquoise-extra-light");
      }
    }
    .trTotal th,
    .trTotal td {
      background: theme-color("turquoise-extra-light");
    }
  }
  .progress {
    background: theme-color("turquoise-extra-light");
    .progress-bar{
      background: theme-color("turquoise-primary") !important;
    }
  }
  .text-primary{
    color: theme-color("turquoise-primary") !important;
  }
  .nameWrapper {
    a {
      color: theme-color("turquoise-primary");
      &:hover {
        color: theme-color("turquoise-secondary");
      }
    }
  }
  .listContent {
    &:hover,
    &.selected {
      border-color: theme-color("turquoise-light") !important;
      box-shadow: 2px 0px 5px 1px rgba(69, 65, 78, 0.08),
        2px 0px 5px 1px rgba(69, 65, 78, 0.04);
    }
  }
  .hoverMarker{
    border-color: theme-color("turquoise-light") !important;
  }
  .cr-search-form__icon-search {
    color: theme-color("turquoise-light");
  }
  .filterDropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: theme-color("turquoise-primary");
        background: theme-color("turquoise-extra-light") !important;
        a {
          background-color: transparent;
          text-decoration: none;
          color: theme-color("turquoise-primary");
        }
        button{
          color: theme-color("turquoise-primary");
        }
      }
      .dropdown-item:active,
      .dropdown-item.active {
        color: #fff;
        background: theme-color("turquoise-primary") !important;
       
      }
    }
  }
  .css-1rhbuit-multiValue {
    background: theme-color("turquoise-primary") !important;
  }
  .css-xb97g8:hover {
    background: theme-color("turquoise-secondary") !important;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border-color: theme-color("turquoise-primary") !important;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: theme-color("turquoise-primary") !important;
  }
  .sp-task {
    .linkBtn {
      color: theme-color("turquoise-primary") !important;
    }
  }
  .totalTimeLabel {
    background: theme-color("turquoise-primary");
  }
  .timeWrapper {
    span {
      sup {
        color: theme-color("turquoise-primary");
      }
    }
    .location{
      color: theme-color("turquoise-primary");
    }
  }
  .detailDescription {
    svg {
      color: theme-color("turquoise-primary");
    }
  }
  .feedbackBtn {
    border-color: theme-color("turquoise-primary") !important;
    color: theme-color("turquoise-primary");
    &:hover {
      border-color: theme-color("turquoise-secondary") !important;
      background: theme-color("turquoise-secondary") !important;
      color: #fff !important;
    }
  }
  .feedbackIcon {
    color: theme-color("turquoise-secondary");
    :hover {
      color: theme-color("turquoise-primary");
    }
  }
  .feedbackPopover {
    .popover-header {
      background: theme-color("turquoise-primary");
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
    .bs-popover-bottom > .arrow::before,
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: theme-color("turquoise-primary") !important;
    }
  }
  .uploadButton {
    background: theme-color("turquoise-primary");
  }
  .rolesList {
    ul {
      li {
        background: theme-color("turquoise-secondary");
      }
    }
  }
  .tooltip-inner {
    background: theme-color("turquoise-primary");
  }
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: theme-color("turquoise-primary") !important;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: theme-color("turquoise-primary") !important;
  }
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{
    border-bottom-color: theme-color("turquoise-primary") !important;
  }
  .text-themeColor{
    color: theme-color("turquoise-primary");
  }
  .table td a {
    color: theme-color("turquoise-primary");
  }
}
