.payslip{
  
.salaryinfotable{
   th, td {
        padding:0;
        vertical-align: top; 
        border:none;
}
}
.salaryactionbtn{
    margin: 5px;
}
}